// Themeprovider should be imported before any components that use themes
// See https://github.com/mui/material-ui/issues/31835#issuecomment-1519178057
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

import Legacy from "./features/legacy"
import "dayjs/locale/en-gb"

function App() {
  const theme = createTheme()

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <Legacy />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
