/**
 * Calculate API backend base URL using frontend protocol, hostname and port.
 *
 * For local IP-based usage:
 *  - Assume local static frontend host is 127.0.1.x and backend is on 127.0.0.x
 *  - Assume local static HTTP frontend ports are :101xx and backend ports are :100xx
 *  - Assume local static HTTPS frontend ports are :103xx and backend ports are :102xx
 *
 * For DNS-style usage:
 *  - Assume static frontend host is <tenant>.voquis.app (i.e. *.voquis.app wildcard)
 *  - Assume backend host is <tenant>.api.voquis.app (i.e. *.api.voquis.app wildcard)
 */

export default function calculateBackendUrl(
  protocol: string,
  hostname: string,
  port: string | number,
) {
  let backendHostname = hostname
  let backendUrl = ""

  if (typeof port === "string") {
    port = Number(port)
  }

  console.debug(`Frontend host: ${protocol}//${hostname}:${port}`)

  // Extract hostname parts to later optionally insert or update
  const hostnameParts = hostname.split(".")

  if (hostname.match(/^127\.\d+\.\d+\.\d+$/)) {
    // Only change ports for static frontend local ips
    if (hostname.match(/^127\.0\.1\.\d+$/)) {
      // Point hostname to backend
      hostnameParts[2] = "0"
      backendHostname = hostnameParts.join(".")
      // Calculate ports
      if (port >= 10100 && port <= 10200) {
        // Assume HTTP frontend is accessing HTTP backend
        port -= 100
      }

      if (port >= 10300 && port <= 10400) {
        // Assume HTTPS frontend is accessing HTTPS backend
        port -= 100
        protocol = "https:"
      }
    }
  } else {
    // Optionally calculate local hostname ports
    if (hostname.endsWith(".local")) {
      // Calculate ports
      if (port >= 10100 && port <= 10200) {
        // Assume HTTP frontend is accessing HTTP backend
        port -= 100
      }

      if (port >= 10300 && port <= 10400) {
        hostnameParts.splice(1, 0, "api")
        // Assume HTTPS frontend is accessing HTTPS backend
        port -= 100
        protocol = "https:"
      }
    } else if (hostname.endsWith(".voquis.app")) {
      hostnameParts.splice(1, 0, "api")
    }

    // return calculated backend hostname
    backendHostname = hostnameParts.join(".")
  }

  // Handle the case where HTTPS or HTTP do not specify a port number
  if (port === 0) {
    backendUrl = `${protocol}//${backendHostname}`
  } else {
    backendUrl = `${protocol}//${backendHostname}:${port}`
  }

  console.debug(`Backend url: ${backendUrl}`)
  return backendUrl
}
