import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectProjectCount, setProjectCount } from "../insightsSlice"

import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import React from "react"

export default function FilterProjectCount() {
  const projectCount = useAppSelector(selectProjectCount)
  const dispatch = useAppDispatch()

  const handleChangeProjectCount = (
    value:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | undefined,
  ) => {
    dispatch(setProjectCount(Number(value?.target.value)))
  }

  return (
    <Grid item xs={12}>
      <TextField
        label="Max projects"
        type="number"
        fullWidth
        value={projectCount}
        onChange={handleChangeProjectCount}
      />
    </Grid>
  )
}
