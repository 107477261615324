import { useGetProjectStatusesQuery } from "../../../services/api"
import { useAppDispatch } from "../../../app/hooks"
import { addProjectStatuses, removeProjectStatus } from "../insightsSlice"
import { ProjectStatus } from "../../../services/types/projectStatus"

import Checkbox from "@mui/material/Checkbox"
import TextField from "@mui/material/TextField"
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import Grid from "@mui/material/Grid"
import { SyntheticEvent } from "react"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function FilterProjectStatuses() {
  const { data, error, isLoading } = useGetProjectStatusesQuery()
  const dispatch = useAppDispatch()

  const handleChange = (
    event: SyntheticEvent,
    value: ProjectStatus[],
    reason: AutocompleteChangeReason,
    detail: AutocompleteChangeDetails<ProjectStatus> | undefined,
  ) => {
    if (reason === "selectOption") {
      dispatch(addProjectStatuses(value))
    }

    if (reason === "removeOption") {
      dispatch(removeProjectStatus(detail?.option))
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="project-status-filter"
          options={data?.records || []}
          disabled={isLoading}
          disableCloseOnSelect
          onChange={handleChange}
          getOptionLabel={(option) => option?.title}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Project Statuses"
              placeholder="Project Statuses"
            />
          )}
        />
      </Grid>
      {error}
    </>
  )
}
