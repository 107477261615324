import { AlertTitle } from "@mui/material"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"

export default function FullScreenError() {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ width: 1, height: "100vh" }}
    >
      <Alert variant="outlined" severity="error">
        <AlertTitle>Error loading organisation</AlertTitle>
        Please try again or contact your administrator.
      </Alert>
    </Stack>
  )
}
