/**
 * Component to render react content inside legacy jQuery application
 */
import { createPortal } from "react-dom"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import {
  login,
  logout,
  navigate,
  selectIsLoggedIn,
  selectPath,
} from "./legacySlice"
import Index from "../insights/index"
import Application from "../application"

export default function Legacy() {
  const dispatch = useAppDispatch()
  // Add event listeners for custom events from legacy jQuery
  window.addEventListener("voquisLegacyLogin", () => {
    dispatch(login())
  })

  window.addEventListener("voquisLegacyLogout", () => {
    dispatch(logout())
  })

  window.addEventListener("voquisLegacyNavigate", ((event: CustomEvent) => {
    dispatch(navigate(event.detail))
  }) as EventListener)

  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const path = useAppSelector(selectPath)
  const contentDiv = document.getElementById("content-react")

  if (contentDiv) {
    if (path.startsWith("/insights/customers")) {
      if (isLoggedIn) {
        return createPortal(<Index />, contentDiv)
      }
    }
  }

  if (path.startsWith("/v2")) {
    return <Application />
  }

  return <></>
}
