import Filters from "./components/filters"
import Charts from "./components/charts"

import Grid from "@mui/material/Grid"

export default function Index() {
  return (
    <Grid container>
      <Grid item xs={10}>
        <Charts />
      </Grid>
      <Grid item xs={2}>
        <Filters />
      </Grid>
    </Grid>
  )
}
