import { useGetOrganisationQuery } from "../../../services/api"
import { useAppDispatch } from "../../../app/hooks"
import { login } from "../authSlice"

import {
  Box,
  Button,
  Container,
  Chip,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material"

export default function Login() {
  const dispatch = useAppDispatch()

  const handleSubmit = function (event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    dispatch(login())
  }

  const { data } = useGetOrganisationQuery()

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Container component="main" maxWidth="xs">
        <Grid container spacing={3} component="form" onSubmit={handleSubmit}>
          <Grid item textAlign="center" xs={12}>
            <Typography component="h1" variant="h4">
              {data?.display_name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="username"
              label="Username"
              variant="outlined"
              fullWidth
              required
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              required
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained">
              Sign In
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Link href="/" variant="body2">
              Forgot password
            </Link>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Link href="/" variant="body2">
              Back to v1
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Chip
              label={data?.app_environment}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={8} textAlign="right">
            <Chip
              label={data?.app_version}
              size="small"
              variant="outlined"
              title={data?.app_commit}
            />
          </Grid>
          <Grid item xs={12} mt={4}>
            <Typography variant="body2" color="text.secondary" align="center">
              {"Copyright © "}
              {new Date().getFullYear()} &nbsp;
              <Link color="inherit" href="https://voquis.com/">
                Voquis Limited
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
