import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface LegacyState {
  isLoggedIn: boolean
  path: string
}

const initialState: LegacyState = {
  isLoggedIn: false,
  path: "/",
}

export const legacySlice = createSlice({
  name: "legacy",
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true
    },
    logout: (state) => {
      state.isLoggedIn = false
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    navigate: (state, action: PayloadAction<string>) => {
      state.path = action.payload
    },
  },
})

// Actions
export const { login, logout, navigate } = legacySlice.actions

// Selectors
export const selectIsLoggedIn = (state: RootState) => state.legacy.isLoggedIn
export const selectPath = (state: RootState) => state.legacy.path

export default legacySlice.reducer
