import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  selectProjectDateStart,
  selectProjectDateEnd,
  setProjectDateStart,
  setProjectDateEnd,
} from "../insightsSlice"

import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Grid from "@mui/material/Grid"

export default function FilterDates() {
  const projectDateStart = useAppSelector(selectProjectDateStart)
  const projectDateEnd = useAppSelector(selectProjectDateEnd)
  const dispatch = useAppDispatch()

  const handleChangeProjectDateStart = (value: any) => {
    dispatch(setProjectDateStart(value))
  }

  const handleChangeProjectDateEnd = (value: any) => {
    dispatch(setProjectDateEnd(value))
  }

  return (
    <>
      <Grid item xs={12}>
        <DatePicker
          label="Project start"
          defaultValue={projectDateStart}
          onChange={handleChangeProjectDateStart}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          label="Project end"
          defaultValue={projectDateEnd}
          onChange={handleChangeProjectDateEnd}
        />
      </Grid>
    </>
  )
}
