import { useGetCustomersQuery } from "../../../services/api"
import { useAppDispatch } from "../../../app/hooks"
import { addCustomers, removeCustomer } from "../insightsSlice"
import { Customer, GetCustomerArgs } from "../../../services/types/customer"

import Checkbox from "@mui/material/Checkbox"
import TextField from "@mui/material/TextField"
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import Grid from "@mui/material/Grid"
import { SyntheticEvent } from "react"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function FilterCustomers() {
  const getCustomerArgs: GetCustomerArgs = {
    page: 1,
    limit: 1000,
    search: undefined,
  }

  const { data, error, isLoading } = useGetCustomersQuery(getCustomerArgs)
  const dispatch = useAppDispatch()

  const handleChange = (
    event: SyntheticEvent,
    value: Customer[],
    reason: AutocompleteChangeReason,
    detail: AutocompleteChangeDetails<Customer> | undefined,
  ) => {
    if (reason === "selectOption") {
      dispatch(addCustomers(value))
    }

    if (reason === "removeOption") {
      dispatch(removeCustomer(detail?.option))
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="customer-filter"
          options={data?.records || []}
          disabled={isLoading}
          disableCloseOnSelect
          onChange={handleChange}
          getOptionLabel={(option) => option?.title}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Customers" placeholder="Customers" />
          )}
        />
      </Grid>
      {error}
    </>
  )
}
