import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { Customer } from "../../services/types/customer"
import { ProjectStatus } from "../../services/types/projectStatus"
import dayjs from "dayjs"
import { Dayjs } from "dayjs"

export interface InsightsState {
  customers: Customer[]
  projectStatuses: ProjectStatus[]
  projectDateStart: Dayjs
  projectDateEnd: Dayjs
  projectCount: number
}

const initialState: InsightsState = {
  customers: [],
  projectStatuses: [],
  projectDateStart: dayjs().subtract(1, "year"),
  projectDateEnd: dayjs(),
  projectCount: 50,
}

export const insightsSlice = createSlice({
  name: "insights",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    // Customer filters
    addCustomers: (state, action: PayloadAction<Customer[]>) => {
      state.customers = action.payload
    },
    removeCustomer: (state, action: PayloadAction<Customer | undefined>) => {
      state.customers = state.customers.filter((customer) => {
        return customer.id !== action.payload?.id
      })
    },
    // Project Status filters
    addProjectStatuses: (state, action: PayloadAction<ProjectStatus[]>) => {
      state.projectStatuses = action.payload
    },
    removeProjectStatus: (
      state,
      action: PayloadAction<ProjectStatus | undefined>,
    ) => {
      state.projectStatuses = state.projectStatuses.filter((projectStatus) => {
        return projectStatus.id !== action.payload?.id
      })
    },
    // Project date filters
    setProjectDateStart: (state, action: PayloadAction<Dayjs>) => {
      state.projectDateStart = action.payload
    },
    setProjectDateEnd: (state, action: PayloadAction<Dayjs>) => {
      state.projectDateEnd = action.payload
    },
    // Project count
    setProjectCount: (state, action: PayloadAction<number>) => {
      state.projectCount = action.payload
    },
  },
})

// Actions
export const {
  // Customer filters
  addCustomers,
  removeCustomer,
  // Project status filters
  addProjectStatuses,
  removeProjectStatus,
  // Project date filters
  setProjectDateStart,
  setProjectDateEnd,
  // Project count
  setProjectCount,
} = insightsSlice.actions

// Selectors
export const selectCustomers = (state: RootState) => state.insights.customers
export const selectProjectDateStart = (state: RootState) =>
  state.insights.projectDateStart
export const selectProjectDateEnd = (state: RootState) =>
  state.insights.projectDateEnd
export const selectProjectStatuses = (state: RootState) =>
  state.insights.projectStatuses
export const selectProjectCount = (state: RootState) =>
  state.insights.projectCount

export default insightsSlice.reducer
