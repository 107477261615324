import { useAppSelector } from "../../app/hooks"
import { selectIsLoggedIn } from "./authSlice"
import Dashboard from "../dashboard"
import Login from "./components/login"

export default function Auth() {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  return isLoggedIn ? <Dashboard /> : <Login />
}
