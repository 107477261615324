import CircularProgress from "@mui/material/CircularProgress"
import Stack from "@mui/material/Stack"

export default function FullScreenLoader() {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      sx={{ width: 1, height: "100vh" }}
    >
      <CircularProgress size="5rem" />
    </Stack>
  )
}
