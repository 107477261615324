import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import insightsReducer from "../features/insights/insightsSlice"
import legacyReducer from "../features/legacy/legacySlice"
import authReducer from "../features/auth/authSlice"
import { api } from "../services/api"

export const store = configureStore({
  reducer: {
    legacy: legacyReducer,
    auth: authReducer,
    insights: insightsReducer,
    // Add the generated api reducer as a specific top-level slice
    [api.reducerPath]: api.reducer,
  },
  // Api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

// Required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
