import { Button } from "@mui/base"
import { useGetOrganisationQuery } from "../../services/api"
import { logout } from "../auth/authSlice"

import { Link, Typography } from "@mui/material"
import { useDispatch } from "react-redux"

export default function Dashboard() {
  const { data } = useGetOrganisationQuery()

  const dispatch = useDispatch()
  const handleLogoutClick = function () {
    dispatch(logout())
  }

  return (
    <>
      <Typography component="h1" variant="h4">
        {data?.display_name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <Link href="/">Back to v1</Link>
      </Typography>
      <Button onClick={handleLogoutClick}>Log out</Button>
    </>
  )
}
