import { useGetOrganisationQuery } from "../../services/api"
import FullScreenLoader from "./components/fullScreenLoader"
import FullScreenError from "./components/fullScreenError"
import Auth from "../auth"

export default function Application() {
  const { error, isLoading } = useGetOrganisationQuery()

  return error ? (
    <FullScreenError />
  ) : isLoading ? (
    <FullScreenLoader />
  ) : (
    <Auth />
  )
}
